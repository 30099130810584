import { FC } from "react";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { PrismicLink } from "src/atoms";

const MobileStack: FC<{
  linksGroups: Gatsby.SiteFooterDataBodyLinksGroupFragment[];
}> = ({ linksGroups }) => {
  return (
    <Box
      w="full"
      display={{ base: "block", md: "none" }}
      sx={{
        columnCount: 2,
        columnGap: "space-32",
        ".item": {
          marginBottom: "space-40",
          breakInside: "avoid-column",
        },
      }}
    >
      {linksGroups.map((group) => {
        if (!group) return null;
        return (
          <VStack
            key={group.primary?.title}
            className="item"
            flex="1"
            textAlign="left"
            align="flex-start"
            spacing="space-12"
          >
            <Heading
              as="h3"
              marginBottom="space-4"
              variant="heading-3"
              fontSize="font-24"
            >
              {group.primary?.title}
            </Heading>

            {group.items?.map((item) => {
              if (!item?.link) return null;

              return (
                <PrismicLink
                  link={item.link}
                  key={item.link_label}
                  fontSize="font-15"
                >
                  {item.link_label}
                </PrismicLink>
              );
            })}
          </VStack>
        );
      })}
    </Box>
  );
};

export default MobileStack;
