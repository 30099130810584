import { FC } from "react";
import {
  Box,
  Container,
  Stack,
  VStack,
  Wrap,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { PrismicLink, ShiIcon } from "src/atoms";
import { Link } from "src/atoms/PrismicLink";
import useOpenCookiesSettings from "src/components/Cookies/useOpenCookiesSettings";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";
import DesktopStack from "./components/DesktopStack";
import MobileStack from "./components/MobileStack";
import { SvgOrImg } from "../SvgOrImg";
import RatingStack from "./components/RatingStack";
import SocialBlock from "./components/SocialBlock";
import StoreBlock from "./components/StoreBlock";

const SiteFooter: FC<{
  isFull: boolean;
  override?: Gatsby.Maybe<Gatsby.SiteFooterFragment>;
}> = ({ isFull, override }) => {
  const { prismicSiteFooter } = useStaticQuery<Gatsby.SiteFooterDataQuery>(graphql`
    query SiteFooterData {
      prismicSiteFooter(tags: { eq: "Is default instance" }) {
        ...SiteFooter
      }
    }
  `);

  const data = override?.data ?? prismicSiteFooter?.data;

  if (!data?.body) throw Error();

  const { legal_links, legal_mentions } = data;

  let highlights: Gatsby.SiteFooterDataBodyHighlightsFragment | undefined;

  const linksGroups: Gatsby.SiteFooterDataBodyLinksGroupFragment[] = [];

  data.body.forEach((slice) => {
    if (slice?.__typename === "PrismicSiteFooterDataBodyHighlights") {
      highlights = slice;
    }
    if (slice?.__typename === "PrismicSiteFooterDataBodyLinksGroup") {
      linksGroups.push(slice);
    }
  });

  const openCookieSettings = useOpenCookiesSettings();

  return (
    <Container
      as="footer"
      marginTop={{ base: "space-40", md: "52px" }}
      paddingX="0"
      paddingBottom={{ base: "space-80", md: "space-80" }}
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      <Container size="block" as="nav" paddingY="0" marginX="auto">
        {isFull && (
          <Box maxW="unset" mb={{ base: "space-40", md: "space-80" }}>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "space-40", lg: "space-160" }}
            >
              <Box>
                <IconButton
                  as={GatsbyLink}
                  aria-label="Shine logo"
                  variant="ghost"
                  paddingY="0"
                  icon={
                    <ShiIcon
                      name="ShineLogo"
                      width={{ base: "70px", md: "96px" }}
                      height="auto"
                    />
                  }
                  to="/"
                  _hover={{
                    backgroundColor: "initial",
                  }}
                />

                <RatingStack
                  globalRating={data.global_rating?.text}
                  appStoreRating={data.app_store_rating?.text}
                  playStoreRating={data.play_store_rating?.text}
                  trustpilotRating={data.trustpilot_rating?.text}
                />
              </Box>

              <Box display={{ base: "block", md: "none" }}>
                <StoreBlock />
              </Box>

              <MobileStack linksGroups={linksGroups} />
              <DesktopStack linksGroups={linksGroups} />
            </Stack>

            <Flex
              display={{ base: "flex", md: "none" }}
              justifyContent="center"
              mt="space-40"
            >
              <SocialBlock data={data} />
            </Flex>

            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={{ base: "space-16", sm: "space-32" }}
              mt={{ base: "space-40", md: "space-80" }}
            >
              {highlights?.items?.map((highlight, index) => {
                if (!highlight) return null;

                return (
                  <VStack
                    key={index}
                    w={'100%'}
                    borderRadius="lg"
                    textAlign={{ base: "center", sm: "left" }}
                    align={{ base: "center", sm: "flex-start" }}
                    bg="greyLight-4"
                    p={{ base: "space-24", md: "space-40" }}
                    spacing="space-16"
                  >
                    <Box>
                      {highlight.illustration?.document?.__typename ===
                        "PrismicElementImage" 
                          ? <SvgOrImg
                              imageFile={highlight.illustration?.document.data?.image_file}
                              size={64}
                            />
                          : null
                      }
                    </Box>
                    <Box
                      sx={{
                        ".chakra-link": {
                          marginTop: "space-24",
                          display: "inline-block",
                        },
                      }}
                    >
                      <Renderer
                        overrides={{
                          [Element.paragraph]: (args: any) =>
                            paragraph({
                              ...args,
                              overrideProps: {
                                fontSize: { base: "font-16", sm: "font-15" },
                                _last: {
                                  mb: 0,
                                },
                              },
                            }),
                        }}
                        field={highlight.content}
                      />
                    </Box>
                  </VStack>
                );
              })}
            </Stack>
          </Box>
        )}

        <Flex
          display={{ base: "none", md: "flex" }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gridGap="space-16"
          mb="space-40"
          maxW="initial"
        >
          <StoreBlock />

          <SocialBlock data={data} />
        </Flex>

        <Stack maxW="unset" spacing="space-40">
          <Box
            textAlign={{ base: "center", md: "left" }}
            sx={{
              a: {
                color: "inherit",
              },
              "p:last-of-type": {
                marginBottom: "0",
              },
            }}
          >
            <Renderer
              overrides={{
                [Element.paragraph]: (args: any) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      fontSize: "font-15",
                      color: { base: "text-primary", md: "text-secondary" },
                    },
                  }),
              }}
              field={legal_mentions}
            />
          </Box>
          <Wrap
            direction={{ base: "column", md: "row" }}
            color="text-secondary"
            spacing="space-16"
            textAlign="center"
            fontSize="font-15"
            justify="center"
          >
            {legal_links?.map((legalLink) =>
              legalLink?.is_cookies_link ? (
                <Link key={legalLink?.link_label} onClick={openCookieSettings}>
                  {legalLink.link_label}
                </Link>
              ) : (
                legalLink?.link && (
                  <PrismicLink
                    key={legalLink?.link_label}
                    link={legalLink.link}
                  >
                    {legalLink.link_label}
                  </PrismicLink>
                )
              ),
            )}
          </Wrap>
        </Stack>
      </Container>
    </Container>
  );
};

export default SiteFooter;
