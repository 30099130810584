import { FC } from "react";
import { Flex, Link, Text } from "@chakra-ui/react";
import RatingsGradeStars from "src/components/GradeStars";
import { ShiIcon } from "src/atoms";

type GlobalRating = {
  rating: number;
};

type Rating = {
  rating: number;
  link: string;
};

const RatingStack: FC<any> = ({
  globalRating,
  appStoreRating,
  playStoreRating,
  trustpilotRating,
}) => {
  try {
    globalRating = JSON.parse(globalRating) as GlobalRating
    appStoreRating = JSON.parse(appStoreRating) as Rating
    playStoreRating = JSON.parse(playStoreRating) as Rating
    trustpilotRating = JSON.parse(trustpilotRating) as Rating
  } catch (error) {
    return null;
  }

  const ratings = [
    { name: "App Store", icon: "apple", ...appStoreRating },
    { name: "Google Play", icon: "googleplay", ...playStoreRating },
    { name: "Trustpilot", icon: "trustpilot", ...trustpilotRating },
  ].filter(({ rating }) => rating > 0 && rating <= 5);

  return (
    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {globalRating.rating ? <RatingsGradeStars fill="grey-0" my={6} size="24px" spacing="2" value={globalRating.rating} /> : null}

      {ratings.map(({ name, icon, rating, link }, index) => (
        <Link display="block" href={link} key={name} mt={index > 0 ? 3 : 0} target="_blank">
          <Flex alignItems="center">
            <ShiIcon name={icon} />

            <Text fontSize="14" fontWeight="normal" lineHeight="16px" ml="3" whiteSpace="nowrap">
              {rating}&nbsp;/&nbsp;5 sur {name}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  )
};

export default RatingStack;
