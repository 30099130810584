import { FC } from "react";
import {
  Flex,
  StackProps,
  Image,
  Link,
} from "@chakra-ui/react";
import AppStoreImage from "/static/images/appstore.png";
import GooglePlayImage from "/static/images/googleplay.png";

const StoreBlock: FC<StackProps> = ({
  ...rest
}) => {
  return (
    <Flex
      justifyContent={{ base: "space-between", md: "flex-start" }}
      gridGap="space-16"
      {...rest}
    >
      <Link as="a" href="https://apps.apple.com/fr/app/shine-compte-pro-en-ligne/id1159779855" isExternal target="_blank">
        <Image with={{ base: "100%", md: "auto" }} height={{ base: "auto", md: "46px" }} maxHeight="46px" src={AppStoreImage} />
      </Link>

      <Link as="a" href="https://play.google.com/store/apps/details?id=com.shine.app&hl=fr&gl=US" isExternal target="_blank">
        <Image with={{ base: "100%", md: "auto" }} height={{ base: "auto", md: "46px" }} maxHeight="46px" src={GooglePlayImage} />
      </Link>
    </Flex>
  );
};

export default StoreBlock;
