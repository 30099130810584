import { ButtonProps, Button } from "@chakra-ui/react";
import { FC } from "react";

const CarouselArrow: FC<ButtonProps & { light?: boolean }> = ({
  light,
  ...props
}) => (
  <Button
    bg={light ? "greyLight-3" : "bg-primary-active"}
    color={light ? "text-primary" : "white"}
    _hover={
      props.disabled
        ? {}
        : {
            bg: light ? "bg-primary-active" : "grey-1",
            color: light? "white" : "white"
          }
    }
    _disabled={{
      bg: "greyLight-3",
      color: "text-primary",
      opacity: "0.6",
      cursor: "default",
    }}
    paddingY="11px"
    borderRadius="full"
    {...props}
  >
    →
  </Button>
);

export default CarouselArrow;
