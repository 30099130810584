import { useContext } from "react";

import Context from "./Context";

const useOpenCookiesSettings = () => {
  const { openCookiesSettings } = useContext(Context);

  return openCookiesSettings;
};

export default useOpenCookiesSettings;
