const STAR_NUMBER = 5;

export const percentageToStarCount = (percentage: number) =>
  percentage / (100 / STAR_NUMBER);

export const getStarDecomposition = (value: number) => {
  const starCount = percentageToStarCount((value / STAR_NUMBER) * 100);
  const fullStarsCount = Math.floor(starCount);
  const lastStarPercentage = Math.round((starCount - fullStarsCount) * 100);

  return {
    STAR_NUMBER,
    fullStarsCount,
    lastStarPolygon: `polygon(0 0, ${lastStarPercentage}% 0, ${lastStarPercentage}% 100%, 0 100%)`,
  };
};
